import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const base_url = "https://www.hospitaldoispinheiros.com.br/";
// const base_url = 'http://newserver/'

export default new Vuex.Store({
  state: {
    files: base_url,
    server: base_url,
    clinicBody: null,
    doctor: null,
    aboutHistory: null,
    aboutWhoWe: null,
    structure: null,
    internation: null,
    waste: null,
    releases: null,
    releaseItem: null,
    recentsReleases: null,
    videos: null,
    video: null,
    releaseVideos: null,
    photos: null,
    photo: null,
    base_url: base_url,
    simplePage: null,
    videosBornH2P: null,
    videoBornH2P: null,
    baby: null,
    releaseVideosBornH2P: null,
    photosBornH2P: null,
    menuMaternity: null,
    contentMaternity: null,
    maternityCalendar: null,
    aboutClinicImage: null,
    examsClinicImage: null, // todos
    examClinicImage: null, // apenas um
    endoscopyMenu: null,
    contentEndoscopy: null,
    slides: null,
    menuSocial: null,
    menuServices: null,
    menuProjects: null,
    pressRoom: null,
    menuVaccines: null,
    contentVaccines: null,
    partners: [],
    clinicalDuty: [],
    anesthesiologyService: [],
    clinicalUnit: [],
    directors: [],
    director: null,
  },
  mutations: {
    error(state, payload) {
      state.error = payload;
    },
    request(state, payload) {
      state[payload.state] = payload.data;
    },
  },
  actions: {
    request(context, payload) {
      fetch(base_url + payload.url, { method: "POST" })
        .then((resp) => resp.json())
        .then((data) => {
          context.commit("request", {
            state: payload.state,
            data: data,
          });
        });
    },
  },
});
