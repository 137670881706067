<template>
  <v-content v-if="content && content.title">
    <v-card elevation="0">
      <v-img width="100%" max-height="400px" :src="base_url+content.img"></v-img>
      <v-card-text class="black--text" v-html="content.text"></v-card-text>
    </v-card>
  </v-content>
</template>

<script>
export default {
  props: {
    state: String,
    url: String,
  },
  mounted() {
    this.$store.dispatch("request", {
      state: this.state,
      method: "POST",
      url: this.url + this.$route.params.name,
    });
  },
  computed: {
    content() {
      return this.$store.state[this.state];
    },
    base_url() {
      return this.$store.state.base_url;
    },
  },
  watch: {
    "$route.params.name": function () {
      this.$store.dispatch("request", {
        state: this.state,
        method: "POST",
        url: this.url + this.$route.params.name,
      });
    },
  },
};
</script>

<style>
</style>