<template>
  <div>
    <div v-if="!small">
      <v-hover v-slot:default="{ hover }">
        <v-card
          class="mb-3"
          :elevation="hover ? 12 : 0"
          ripple
          :to="baseTo + item.url_pag"
        >
          <v-img class="img-card" :src="base_url + item.img"></v-img>
        </v-card>
      </v-hover>
      <div
        class="
          d-flex
          justify-center
          font-weight-bold
          text-body-1 text-uppercase
        "
        v-html="item.title"
      ></div>
    </div>
    <v-card v-else elevation="0" ripple :to="baseTo + item.url_pag">
      <v-img
        class="img-card"
        :aspect-ratio="1"
        :src="base_url + item.img"
      ></v-img>
      <h4 v-html="item.title"></h4>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    small: Boolean,
    baseTo: String,
  },
  computed: {
    base_url() {
      return this.$store.state.base_url;
    },
  },
};
</script>

<style>
@media (max-width: 480px) {
  .img-card {
    height: 150px !important;
  }
}
</style>