<template>
  <v-app class="main">
    <Carousel class="mb-10" />
    <v-content class="d-flex justify-center col-8">
      <v-container class="content">
        <v-row v-if="menuServices && menuServices.length > 0">
          <v-col
            cols="12"
            sm="6"
            md="6"
            class="d-flex justify-start"
            v-for="item in menuServices"
            :key="item.name"
          >
            <div class="icon-topics d-flex justify-center">
              <router-link
                style="text-decoration: none !important"
                :to="item.path"
              >
                <img :src="base_url + item.icon" width="65" />
              </router-link>
            </div>
            <div>
              <router-link
                style="text-decoration: none !important"
                :to="item.path"
              >
                <div class="title-topic">{{ item.name }}</div>
                <div class="content-topic">{{ item.resum }}</div>
              </router-link>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
    <div class="bg-tab-menu-home">
      <Maternity class="container" />
    </div>
    <div class="clinic-body">
      <ClinicBody class="container" />
    </div>
    <div class="mural">
      <Mural />
    </div>
    <div class="press-room-home pb-10">
      <PressRoom class="container" />
    </div>
    <div>
      <ImageClinic />
    </div>
  </v-app>
</template>

<script>
import Carousel from "./Carousel";
import Maternity from "./Maternity";
import ClinicBody from "@/components/widgets/clinical-body/Index.vue";
import ImageClinic from "./ImageClinic";
import Mural from "./Mural";
import PressRoom from "./PressRoom";

export default {
  components: {
    Carousel,
    Maternity,
    ClinicBody,
    Mural,
    PressRoom,
    ImageClinic,
  },
  data: () => ({
    items: [
      {
        title: "Internações",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  ",
        icon: "/img/main-icons/ambul.png",
      },
      {
        title: "Emergência",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: "/img/main-icons/cama.png",
      },
      {
        title: "Centro Cirúrgico",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: "/img/main-icons/doctor.png",
      },
      {
        title: "Centro de Especialidades",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: "/img/main-icons/maca-cirurg.png",
      },
    ],
  }),
  computed: {
    menuServices() {
      return this.$store.state.menuServices;
    },
    base_url() {
      return this.$store.state.base_url;
    },
  },
};
</script>

<style>
.press-room-home {
  background-color: #f5f5f5 !important;
}
.bg-tab-menu-home {
  border-top: 0.5px solid rgb(184, 184, 184);
  border-bottom: 0.5px solid rgb(184, 184, 184);
  padding-bottom: 40px;
  width: 100%;
  background-color: #f5f5f5 !important;
}

.main {
  width: 100% !important;
}
.topics {
  display: flex !important;
  justify-content: center !important;
}

.icon-topics {
  margin-left: 15px;
  margin-right: 10px;
  width: 65px;
  height: 65px;
}

.content-topic {
  padding: 0px;
  font-size: 0.9rem;
}

.title-topic {
  font-size: 1.2rem;
  font-weight: bold;
}

.content {
  margin-bottom: 50px;
}

@media (min-width: 800px) {
  .content {
    width: 70%;
  }
}
</style>