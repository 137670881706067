<template>
  <div>
    <ToolBar />
    <v-container>
      <v-row justify="center" class="pb-5 pt-4">
        <h1>Nasci no Hospital Dois Pinheiros</h1>
      </v-row>
      <Institutional :categories="categories" />
    </v-container>
  </div>
</template>

<script>
import Institutional from "./Institutional";
import ToolBar from "@/components/ToolBar";

export default {
  components: {
    Institutional,
    ToolBar,
  },
  data: () => ({
    categories: [
      { title: "Fotos", path: "/nasci-no-h2p/fotos" },
      { title: "Videos", path: "/nasci-no-h2p/videos" },
    ],
  }),
};
</script>

<style scoped>
</style>