<template>
  <v-container v-if="exams && exams.length > 0" class="col-12 col-sm-9">
    <div class="tab-menu-img d-flex justify-center">
      <img :src="logo" width="300px" />
    </div>

    <v-tabs
      v-model="active"
      color="#217b43"
      background-color="transparent"
      slider-color="#217b43"
      show-arrows
      centered
    >
      <v-tab
        class="tab-menu-title"
        v-for="(item, i) in items"
        :key="i"
        v-html="item.title"
        ripple
      ></v-tab>

      <v-tabs-items v-model="active">
        <v-tab-item
          class="pt-10 tab-menu-item"
          v-for="(item, i) in items"
          :key="i"
        >
          <TabItem :baseTo="'/clinica-imagem'" :bgColor="false" :item="item" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import TabItem from "@/components/TabItem";

export default {
  created() {
    this.$store.dispatch("request", {
      state: "aboutClinicImage",
      method: "POST",
      url: "getAboutCImage",
    });

    this.$store.dispatch("request", {
      state: "examsClinicImage",
      method: "POST",
      url: "getAllExams",
    });
  },
  name: "Maternity",
  components: {
    TabItem,
  },
  data: () => ({
    active: null,
    logo: "/img/logo-diagn-img.png",
    items: [],
  }),
  computed: {
    exams() {
      return this.$store.state.examsClinicImage;
    },
    about() {
      return this.$store.state.aboutClinicImage;
    },
  },
  watch: {
    about: function () {
      if (
        this.exams &&
        this.exams.length > 0 &&
        this.about &&
        this.about.title
      ) {
        this.items.push({
          title: this.about.title,
          img: this.about.img,
          text: this.about.text,
          url_pag: "",
        });
        this.exams.forEach((element) => {
          this.items.push({
            title: element.title,
            img: element.img,
            text: element.text,
            url_pag: "",
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.bg-imgclinic {
  width: 100%;
}

.imageclinic-item {
  height: 100%;
}

.tab-title {
  font-size: 0.8em !important;
  margin-left: 0px !important;
  font-weight: bold;
  color: #217b43 !important;
}

.tab-subtitle {
  padding: 0px !important;
  margin-bottom: 15px;
  font-weight: bold !important;
  font-size: 1.3em !important;
}

.tab-content {
  padding: 0px !important;
}

.tab-imageclinic .v-slide-group {
  display: -webkit-box !important;
  border-bottom: 1px solid grey !important;
}

.button-tab {
  color: white !important;
}

.imageclinic {
  margin: 60px 0 30px 0px;
}

.content-imgclinic {
  margin-bottom: 60px;
}
</style>