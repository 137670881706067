<template>
  <v-app>
    <main-header></main-header>
    <v-content>
      <router-view></router-view>
    </v-content>
    <main-footer></main-footer>
  </v-app>
</template>

<script>
import MainHeader from "./components/shell/MainHeader";
import MainFooter from "./components/shell/MainFooter";

export default {
  beforeCreate() {
    this.$store.dispatch("request", {
      state: "slides",
      url: "getSlides",
    });

    this.$store.dispatch("request", {
      state: "clinicBody",
      url: "getAllClinicBody",
    });

    this.$store.dispatch("request", {
      state: "aboutHistory",
      url: "getHistory",
    });

    this.$store.dispatch("request", {
      state: "aboutWhoWe",
      url: "getAboutUs",
    });

    this.$store.dispatch("request", {
      state: "structure",
      url: "getStructure",
    });

    this.$store.dispatch("request", {
      state: "internation",
      url: "getInternation",
    });

    this.$store.dispatch("request", {
      state: "waste",
      url: "getWaste",
    });

    this.$store.dispatch("request", {
      state: "releases",
      url: "getAllReleases",
    });

    this.$store.dispatch("request", {
      state: "recentsReleases",
      url: "getRecentsReleases",
    });

    this.$store.dispatch("request", {
      state: "videos",
      url: "getAllVideos",
    });

    this.$store.dispatch("request", {
      state: "releaseVideos",
      url: "getReleaseVideos",
    });

    this.$store.dispatch("request", {
      state: "photos",
      url: "getPhotos",
    });

    this.$store.dispatch("request", {
      state: "photosBornH2P",
      url: "getPhotosBornH2p",
    });

    this.$store.dispatch("request", {
      state: "menuMaternity",
      url: "getMenuMaternity",
    });

    this.$store.dispatch("request", {
      state: "maternityCalendar",
      url: "getCalendar",
    });

    this.$store.dispatch("request", {
      state: "aboutClinicImage",
      url: "getAboutCImage",
    });

    this.$store.dispatch("request", {
      state: "examsClinicImage",
      url: "getAllExams",
    });

    this.$store.dispatch("request", {
      state: "endoscopyMenu",
      url: "getMenuEndoscopy",
    });

    this.$store.dispatch("request", {
      state: "menuSocial",
      url: "getAllSocial",
    });

    this.$store.dispatch("request", {
      state: "menuServices",
      url: "getAllServices",
    });

    this.$store.dispatch("request", {
      state: "menuProjects",
      url: "getAllProjects",
    });

    this.$store.dispatch("request", {
      state: "pressRoom",
      url: "getPressRoom",
    });

    this.$store.dispatch("request", {
      state: "menuVaccines",
      url: "getMenuVaccines",
    });
    this.$store.dispatch("request", {
      state: "partners",
      url: "getAllPartners",
    });
    this.$store.dispatch("request", {
      state: "clinicalDuty",
      url: "getAllClinicalDuty",
    });
    this.$store.dispatch("request", {
      state: "anesthesiologyService",
      url: "getAllAnesthesiologyService",
    });
    this.$store.dispatch("request", {
      state: "clinicalUnit",
      url: "getAllClinicalUnit",
    });
  },
  components: {
    MainHeader,
    MainFooter,
  },
};
</script>

<style>
@font-face {
  font-family: AdgetSans;
  src: url("./fonts/adget-sans.ttf");
}
html {
  font-size: 16px;
}
main {
  font-size: 0.875rrem;
  letter-spacing: 0.25px;
}
h1 {
  font-size: 3rrem;
  font-weight: normal;
  letter-spacing: 0px;
}
h2 {
  font-family: AdgetSans;
  font-size: 2.125rrem;
  font-weight: normal;
  letter-spacing: 0.25px;
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
}
h3 {
  font-family: AdgetSans, Roboto, sans-serif;
  font-size: 1.5rrem;
  font-weight: normal;
  letter-spacing: 0px;
  margin-bottom: 12px;
}
h3::after {
  content: "";
  display: block;
  height: 2px;
  width: 80px;
}
h4 {
  font-size: 1.25rrem;
  font-weight: medium;
  letter-spacing: 0.15px;
}
h5 {
  font-size: 1rrem;
  font-weight: normal;
  letter-spacing: 0.15px;
}
h6 {
  font-size: 0.875rrem;
  font-weight: medium;
  letter-spacing: 0.1px;
}
.v-carousel .fas.fa-chevron-left,
.v-carousel .fas.fa-chevron-right {
  font-size: 26px !important;
}
.v-expansion-panels .fas.fa-chevron-up,
.v-expansion-panels .fas.fa-chevron-down {
  font-size: 18px !important;
}

.simple-slide .v-slide-group__content {
  display: grid;
  grid-auto-flow: column;
  display: grid;
  grid-auto-flow: column;
  padding: 5px;
  grid-auto-columns: calc(100vw - 128px);
}

@media screen and (min-width: 600.001px) and (max-width: 960px) {
  .simple-slide .v-slide-group__content {
    grid-auto-columns: calc((100vw - 125px) / 2);
  }
}
@media screen and (min-width: 960.001px) and (max-width: 1264px) {
  .simple-slide .v-slide-group__content {
    grid-auto-columns: calc((900px - 130px) / 3);
  }
}
@media screen and (min-width: 1264.001px) {
  .simple-slide .v-slide-group__content {
    grid-auto-columns: calc((1185px - 130px) / 3);
  }
}
</style>
