<template>
  <div v-if="releases && releases.length > 0">
    <ToolBar title="Releases" />
    <v-container>
      <v-col>
        <h3>Releases e Notícias</h3>
      </v-col>

      <v-row class="px-5">
        <v-col cols="12" md="8">
          <v-card
            v-for="(release, i) in releases"
            :key="i"
            elevation="0"
            class="pb-2 mb-8"
          >
            <v-row>
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="page-releases-list-item"
                  :elevation="hover ? 2 : 0"
                  :to="'/releases/' + release.url_pag"
                  width="100%"
                >
                  <img
                    class="page-releases-list-item-img"
                    :src="base_url + release.img"
                  />
                  <div class="px-4 py-1">
                    <v-card-title
                      class="page-releases-list-item-title pa-0"
                      v-html="release.title"
                    ></v-card-title>
                    <v-card-text class="pa-0">
                      <div
                        class="body-news-main text-justify pt-2"
                        v-html="release.text"
                      ></div>
                      <div class="d-flex justify-space-between">
                        <div class="d-flex justify-start font-weight-bold">
                          {{ formateDate(release.date) }}
                        </div>
                      </div>
                    </v-card-text>
                  </div>
                </v-card>
              </v-hover>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ToolBar from "@/components/ToolBar";
import moment from "moment";

export default {
  components: {
    ToolBar,
  },
  methods: {
    formateDate(date) {
      return moment(String(date)).format("DD/MM/YYYY hh:mm");
    },
  },
  computed: {
    releases() {
      return this.$store.state.releases;
    },
    base_url() {
      return this.$store.state.base_url;
    },
    recents() {
      return this.$store.state.recentsReleases;
    },
  },
};
</script>

<style scoped>
.page-releases-list-item {
  display: grid;
  grid-template-columns: max-content auto;
}
.page-releases-list-item-title {
  line-height: 24px;
}
.page-releases-list-item-img {
  aspect-ratio: 16/11;
  object-fit: cover;
  width: 240px;
}

.tolbar-releases {
  border-bottom: 0.5px solid grey !important;
}

.body-news-main {
  max-height: 100px;
  overflow-y: hidden;
}
</style>