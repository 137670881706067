<template>
  <v-row v-if="internation && internation.length > 0" justify="center" class="mx-auto">
    <v-col cols="12" sm="10" md="9">
      <v-row justify="start" class="col-12">
        <div class="title-guide" v-html="capa.title"></div>
      </v-row>

      <!-- drop items -->
      <v-row justify="center" class="pb-10">
        <v-col>
          <div class="text-guide" v-html="capa.text"></div>
        </v-col>
        <v-col class="col-dropdown" cols="12" sm="6">
          <v-row>
            <v-col cols="12" sm="12" class="col-dics">
              <v-expansion-panels accordion focusable>
                <v-expansion-panel v-for="(item,i) in internation" :key="i">
                  <div v-if="item.complemento !== 'capa'">
                    <v-expansion-panel-header>
                      <v-row>
                        <v-col cols="11" class="dics-header d-flex justify-start">
                          <strong v-html="item.title"></strong>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="mt-2" v-html="item.text"></div>
                    </v-expansion-panel-content>
                  </div>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Guide',
  data: () => ({
 
  }),
  computed: {
    internation() {
      return this.$store.state.internation
    },
    base_url() {
      return this.$store.state.base_url
    },
    capa() {
      return this.internation.find(element => { if (element.complemento == 'capa') return true })
    }
  }
}
</script>

<style  scoped>
.col-dics {
  padding: 20px;
}

.dics-header {
  padding: 0px !important;
}

.title-dics {
  font-size: 2rem;
  font-weight: bold;
  padding: 20px;
}

.col-img {
  padding: 20px;
}

.text-guide {
  font-size: 1.1rem;
  padding: 15px;
  padding-bottom: 0px;
}

.title-guide {
  font-size: 1.9rem;
  padding: 20px;
}

.col-dropdown {
  padding: 0px !important;
}
</style>