<template>
  <div v-if="release && release.title">
    <ToolBar title="Releases" />
    <v-container>
      <div class="px-5">
        <v-row>
          <v-col cols="12" md="8">
            <v-card elevation="0">
              <h3 class="pt-4" v-html="release.title"></h3>
              <share class="mb-3" />
              <v-img
                max-height="400px"
                width="100%"
                :src="base_url + release.img"
              ></v-img>
              <div class="d-flex justify-start pt-2">
                {{ formateDate(release.date) }}
              </div>
              <v-card-text class="pa-0">
                <div
                  class="body-news text-justify black--text text-body-1"
                  v-html="release.text"
                ></div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <CardRecents
              :baseTo="'/releases/'"
              :recents="recents"
              :btnViewMore="true"
              :viewMoreUrl="'/releases'"
            />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import CardRecents from "@/components/press/CardRecents";
import ToolBar from "@/components/ToolBar";
import moment from "moment";
import Share from "../section/Share.vue";

export default {
  beforeCreate() {
    this.$store.dispatch("request", {
      state: "releaseItem",
      method: "POST",
      url: "getRelease/" + this.$route.params.name,
    });
  },
  components: {
    CardRecents,
    ToolBar,
    Share,
  },
  methods: {
    formateDate(date) {
      return moment(String(date)).format("DD/MM/YYYY hh:mm");
    },
  },
  computed: {
    recents() {
      return this.$store.state.releases;
    },
    release() {
      return this.$store.state.releaseItem;
    },
    base_url() {
      return this.$store.state.base_url;
    },
  },
  watch: {
    "$route.params.name": function () {
      this.$store.dispatch("request", {
        state: "releaseItem",
        method: "POST",
        url: "getRelease/" + this.$route.params.name,
      });
    },
  },
};
</script>

<style scoped>
.release-title {
  overflow-y: hidden;
  max-height: 80px;
}
.body-news {
  padding-top: 25px;
  padding-bottom: 35px;
}
</style>