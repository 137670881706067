<template>
  <v-container>
    <div v-if="logo" class="logo-institutional">
      <v-row justify="center" class="py-3">
        <v-img
          max-height="150px"
          max-width="300px"
          width="100%"
          :src="logo"
        ></v-img>
      </v-row>
    </div>
    <v-row class="py-10 px-1">
      <v-card outlined width="100%">
        <v-expansion-panels hover flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="py-0 px-1">
              <v-icon class="px-4" color="red lighten-2">mdi-needle</v-icon>
              <div class="py-2 col-12 d-flex justify-center title">
                <span>Calendário de Vacinas</span>
              </div>
              <v-icon class="px-4" color="red lighten-2">mdi-needle</v-icon>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="pa-0" elevation="0">
                <v-tabs
                  background-color="red lighten-2"
                  color="white"
                  icons-and-text
                  centered
                  optional
                  show-arrows
                >
                  <v-tab
                    v-for="(item, i) in calendars"
                    :key="i"
                    :href="item.link"
                  >
                    <div class="white--text" v-html="item.title"></div>
                  </v-tab>
                </v-tabs>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-row>
    <template v-if="menu && menu.length > 0">
      <v-tabs class="mb-4" show-arrows color="green">
        <v-tab
          class="tab-name"
          v-for="(item, i) in menu"
          :key="i"
          :to="item.url_pag"
          v-html="item.title"
        >
        </v-tab>
      </v-tabs>
      <ItemTabPage :state="'contentVaccines'" :url="'getContentVaccines/'" />
    </template>
  </v-container>
</template>

<script>
import ItemTabPage from "@/components/ItemTabPage";

export default {
  components: {
    ItemTabPage,
  },
  data: () => ({
    logo: "/img/vaccines.png",
    calendars: [
      {
        title: "Prematuro",
        link: "https://sbim.org.br/images/calendarios/calend-sbim-prematuro.pdf",
      },
      {
        title: "Criança<br>0 a 10 anos",
        link: "https://sbim.org.br/images/calendarios/calend-sbim-crianca.pdf",
      },
      {
        title: "Adolescente<br>11 à 19 anos",
        link: "https://sbim.org.br/images/calendarios/calend-sbim-adolescente.pdf",
      },
      {
        title: "Gestante",
        link: "https://sbim.org.br/images/calendarios/calend-sbim-gestante.pdf",
      },
      {
        title: "Adulto<br>20 a 59 anos",
        link: "https://sbim.org.br/images/calendarios/calend-sbim-adulto.pdf",
      },
      {
        title: "Idoso<br>mais de 60 anos",
        link: "https://sbim.org.br/images/calendarios/calend-sbim-idoso.pdf",
      },
      {
        title: "Ocupacional",
        link: "https://sbim.org.br/images/calendarios/calend-sbim-ocupacional.pdf",
      },
    ],
  }),
  computed: {
    menu() {
      return this.$store.state.menuVaccines;
    },
    base_url() {
      return this.$store.state.base_url;
    },
  },
};
</script>