<template>
  <div>
    <SimplePage
      v-if="simplePage && simplePage.title"
      :text="simplePage.text"
      :title="simplePage.title"
      :img="base_url + simplePage.img"
    />
  </div>
</template>

<script>
import SimplePage from "@/components/templates/SimplePage";

export default {
  created() {
    this.$store.dispatch("request", {
      state: "simplePage",
      method: "POST",
      url: "getService/" + this.$route.params.name,
    });
  },
  components: {
    SimplePage,
  },
  computed: {
    simplePage() {
      return this.$store.state.simplePage;
    },
    base_url() {
      return this.$store.state.base_url;
    },
  },
  watch: {
    "$route.params.name": function () {
      this.$store.dispatch("request", {
        state: "simplePage",
        method: "POST",
        url: "getService/" + this.$route.params.name,
      });
    },
  },
};
</script>

<style>
</style>