<template>
  <div>
    <ToolBar title="Fotos e Eventos" />
    <v-container class="pb-16" v-if="photos && photos.length > 0">
      <h4 class="py-5 d-flex justify-center" v-html="photos[0].title"></h4>
      <v-row justify="center">
        <div v-html="photos[0].text"></div>
      </v-row>
      <v-row justify="start">
        <v-col cols="6" sm="3" v-for="(item, i) in photos" :key="i">
          <v-hover v-slot:default="{ hover }">
            <v-card :elevation="hover ? 12 : 0">
              <v-img
                height="100%"
                max-height="200px"
                width="100%"
                class="image"
                :src="base_url + item.src"
              >
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal display-3 white--text"
                    style="height: 100%"
                  >
                    <v-btn small fab @click="openModal(photos, i)">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </div>
                </v-expand-transition>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
        <TinyBox :loop="true" v-model="index" :images="imagesBox" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ToolBar from "@/components/ToolBar";
import TinyBox from "vue-tinybox";

export default {
  beforeCreate() {
    this.$store.dispatch("request", {
      state: "photo",
      method: "POST",
      url: "getPhoto/" + this.$route.params.name,
    });
  },
  components: {
    // CardPhotos,
    TinyBox,
    ToolBar,
  },
  data: () => ({
    index: null,
    imagesBox: [],
    viewed: false,
    itemsModal: {},
  }),
  computed: {
    photos() {
      return this.$store.state.photo;
    },
    base_url() {
      return this.$store.state.base_url;
    },
  },
  methods: {
    openModal(data = null, i) {
      if (data) {
        this.imagesBox = [];
        data.forEach((element) => {
          let newSrc;
          newSrc = this.base_url + element.src;
          this.imagesBox.push(newSrc);
        });
        this.index = i;
      }
    },
  },
  watch: {
    "$route.params.name": function () {
      this.$store.dispatch("request", {
        state: "photo",
        method: "POST",
        url: "getPhoto/" + this.$route.params.name,
      });
    },
  },
};
</script>

<style>
.v-card--reveal {
  height: 100%;
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}
</style>
