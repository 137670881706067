import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/components/pages/custom/home/Index.vue";
import Attendance from "@/components/pages/custom/Attendance.vue";
import About from "@/components/pages/about/Index.vue";
import Page from "@/components/templates/Page.vue";
import ServiceItem from "@/components/pages/service/Item.vue";
// import DirectorItem from "@/components/pages/director/Item.vue";
import Profile from "@/components/pages/custom/Profile.vue";
import Maternity from "@/components/pages/custom/Maternity.vue";
import Endoscopy from "@/components/pages/custom/Endoscopy.vue";
import ClinicImage from "@/components/pages/clinic-image/Index.vue";
import MainVaccine from "@/components/pages/custom/Vaccine.vue";
import ReleasesItem from "@/components/press/ReleasesItem";
import MainReleases from "@/components/pages/custom/Releases";
import MainVideos from "@/components/pages/custom/Videos";
import VideosItem from "@/components/press/VideosItem";
import Video from "@/components/press/Video";
import MainBornInH2P from "@/components/pages/projects/main-born-in-h2p/Index";
import MainPhotosEvents from "@/components/pages/custom/PhotosEvents";
import PhotosEventsItem from "@/components/press/PhotosEventsItem";
import ExhibitionsList from "@/components/pages/exhibition/List";
import ExhibitionsFathersDay from "@/components/pages/exhibition/FathersDay";
import Staff from "@/components/pages/custom/Staff";

const base_name = "Hospital Dois Pinheiros";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: base_name,
    component: Home,
  },
  {
    path: "/nasci-no-h2p",
    name: "Nasci no H2P - " + base_name,
    component: MainBornInH2P,
    redirect: { path: "/nasci-no-h2p/videos" },
    children: [
      {
        path: "/nasci-no-h2p/videos",
        name: "Videos - " + base_name,
        component: () => import("@/components/press/VideosBornInH2P.vue"),
      },
      {
        path: "/nasci-no-h2p/fotos",
        name: "Fotos - " + base_name,
        component: () => import("@/components/press/PhotosBornInH2P.vue"),
      },
      {
        path: "/nasci-no-h2p/fotos/:name",
        name: "Foto - " + base_name,
        component: () => import("@/components/pages/custom/Baby.vue"),
      },
    ],
  },
  {
    path: "/depoimento/:name",
    name: "Depoimento - " + base_name,
    component: () => import("@/components/VideoBornH2P.vue"),
  },
  {
    path: "/sobre",
    name: "Sobre nós - " + base_name,
    component: About,
  },
  // {
  //   path: "/sobre/diretores/:director",
  //   name: "Diretor - " + base_name,
  //   component: DirectorItem,
  // },
  {
    path: "/atendimento",
    name: "Atendimento - " + base_name,
    component: Attendance,
  },
  {
    path: "/releases",
    name: "Releases - " + base_name,
    component: MainReleases,
  },
  {
    path: "/releases/:name",
    name: "Release - " + base_name,
    component: ReleasesItem,
  },
  {
    path: "/videos",
    name: "Galeria de Vídeos - " + base_name,
    component: MainVideos,
  },
  {
    path: "/videos/:name",
    name: "Video - " + base_name,
    component: VideosItem,
  },
  {
    path: "/video/:name",
    name: "Video",
    component: Video,
  },
  {
    path: "/fotos-e-eventos",
    name: "Fotos e Eventos - " + base_name,
    component: MainPhotosEvents,
  },
  {
    path: "/fotos-e-eventos/:name",
    name: "Galeria - " + base_name,
    component: PhotosEventsItem,
  },
  {
    path: "/exposicoes",
    name: "Exposições - " + base_name,
    component: ExhibitionsList,
  },
  {
    path: "/exposicoes/diadospais",
    name: "Exposição - " + base_name,
    component: ExhibitionsFathersDay,
  },
  {
    path: "/page/:name",
    name: "Page - " + base_name,
    component: Page,
  },
  {
    path: "/service/:name",
    name: "Serviço - " + base_name,
    component: ServiceItem,
  },
  {
    path: "/perfil/:name",
    name: "Perfil - " + base_name,
    component: Profile,
  },
  {
    path: "/sala-de-vacinas/:name",
    name: "Sala de Vacinas - " + base_name,
    component: MainVaccine,
  },
  {
    path: "/endoscopia/:name",
    name: "Endoscopia - " + base_name,
    component: Endoscopy,
  },
  {
    path: "/maternidade/:name",
    name: "Maternidade - " + base_name,
    component: Maternity,
  },
  {
    path: "/clinica-imagem/",
    name: "Clínica de Imagem - " + base_name,
    component: ClinicImage,
  },
  {
    path: "/equipe/:staff",
    name: "Equipe - " + base_name,
    component: Staff,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  document.title = to.name;
  window.scrollTo(0, 0);
});

export default router;
