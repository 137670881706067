<template>
  <div class="page-exhibition-fathersday">
    <v-container class="pt-0">
      <v-img
        class="mx-auto mb-10"
        src="/img/exposicoes/diadospais/img.png"
        width="600px"
        max-width="100%"
      >
      </v-img>

      <div class="page-exhibition-fathersday-winners mb-4 mb-sm-12">
        <v-card
          v-for="(item, i) in items.slice(0, 3)"
          :key="i"
          @click="openModal(items, i)"
        >
          <picture class="d-flex">
            <img :src="base_url + item" />
          </picture>
        </v-card>
      </div>
      <div class="page-exhibition-fathersday-list mb-4">
        <v-card
          v-for="(item, i) in items.slice(3)"
          :key="i"
          class="d-flex"
          @click="openModal(items, i)"
        >
          <img :src="base_url + item" />
        </v-card>
      </div>
    </v-container>
    <TinyBox :loop="true" v-model="index" :images="imagesBox" />
  </div>
</template>

<script>
import TinyBox from "vue-tinybox";

export default {
  components: {
    TinyBox,
  },
  data: () => ({
    index: null,
    imagesBox: [],
    viewed: false,
    itemsModal: {},
    items: [
      "/img/exposicoes/diadospais/1.jpeg",
      "/img/exposicoes/diadospais/2.jpeg",
      "/img/exposicoes/diadospais/3.jpeg",
      "/img/exposicoes/diadospais/4.jpeg",
      "/img/exposicoes/diadospais/5.jpeg",
      "/img/exposicoes/diadospais/6.png",
      "/img/exposicoes/diadospais/7.jpeg",
      "/img/exposicoes/diadospais/8.jpeg",
      "/img/exposicoes/diadospais/foto 59.jpeg",
      "/img/exposicoes/diadospais/foto 84.jpeg",
      "/img/exposicoes/diadospais/foto1.png",
      "/img/exposicoes/diadospais/foto10.jpeg",
      "/img/exposicoes/diadospais/foto11.jpeg",
      "/img/exposicoes/diadospais/foto12.jpeg",
      "/img/exposicoes/diadospais/foto13.jpeg",
      "/img/exposicoes/diadospais/foto15.jpeg",
      "/img/exposicoes/diadospais/foto16.jpeg",
      "/img/exposicoes/diadospais/foto17.jpeg",
      "/img/exposicoes/diadospais/foto18.jpeg",
      "/img/exposicoes/diadospais/foto19.jpeg",
      "/img/exposicoes/diadospais/foto2.jpeg",
      "/img/exposicoes/diadospais/foto20.jpeg",
      "/img/exposicoes/diadospais/foto22.jpeg",
      "/img/exposicoes/diadospais/foto23.jpeg",
      "/img/exposicoes/diadospais/foto24.jpeg",
      "/img/exposicoes/diadospais/foto25.jpeg",
      "/img/exposicoes/diadospais/foto26.jpeg",
      "/img/exposicoes/diadospais/foto27.jpeg",
      "/img/exposicoes/diadospais/foto28.jpeg",
      "/img/exposicoes/diadospais/foto29.jpeg",
      "/img/exposicoes/diadospais/foto30.jpeg",
      "/img/exposicoes/diadospais/foto31.jpeg",
      "/img/exposicoes/diadospais/foto32.jpeg",
      "/img/exposicoes/diadospais/foto33.jpeg",
      "/img/exposicoes/diadospais/foto34.jpeg",
      "/img/exposicoes/diadospais/foto35.jpeg",
      "/img/exposicoes/diadospais/foto36.jpeg",
      "/img/exposicoes/diadospais/foto37.jpeg",
      "/img/exposicoes/diadospais/foto38.jpeg",
      "/img/exposicoes/diadospais/foto39.jpeg",
      "/img/exposicoes/diadospais/foto4.jpeg",
      "/img/exposicoes/diadospais/foto41.jpeg",
      "/img/exposicoes/diadospais/foto42.jpeg",
      "/img/exposicoes/diadospais/foto43.png",
      "/img/exposicoes/diadospais/foto44.jpg",
      "/img/exposicoes/diadospais/foto45.jpeg",
      "/img/exposicoes/diadospais/foto46.jpeg",
      "/img/exposicoes/diadospais/foto47.jpeg",
      "/img/exposicoes/diadospais/foto49.jpeg",
      "/img/exposicoes/diadospais/foto5.jpeg",
      "/img/exposicoes/diadospais/foto50.jpeg",
      "/img/exposicoes/diadospais/foto51.jpeg",
      "/img/exposicoes/diadospais/foto53.jpeg",
      "/img/exposicoes/diadospais/foto54.jpeg",
      "/img/exposicoes/diadospais/foto55.jpeg",
      "/img/exposicoes/diadospais/foto57.jpeg",
      "/img/exposicoes/diadospais/foto58.jpeg",
      "/img/exposicoes/diadospais/foto6.jpeg",
      "/img/exposicoes/diadospais/foto60.jpeg",
      "/img/exposicoes/diadospais/foto61.jpeg",
      "/img/exposicoes/diadospais/foto62.jpeg",
      "/img/exposicoes/diadospais/foto63.jpeg",
      "/img/exposicoes/diadospais/foto64.jpeg",
      "/img/exposicoes/diadospais/foto65.jpeg",
      "/img/exposicoes/diadospais/foto66.jpeg",
      "/img/exposicoes/diadospais/foto67.jpeg",
      "/img/exposicoes/diadospais/foto68.jpeg",
      "/img/exposicoes/diadospais/foto69.jpeg",
      "/img/exposicoes/diadospais/foto7.jpeg",
      "/img/exposicoes/diadospais/foto70.jpeg",
      "/img/exposicoes/diadospais/foto71.jpeg",
      "/img/exposicoes/diadospais/foto72.jpeg",
      "/img/exposicoes/diadospais/foto73.jpeg",
      "/img/exposicoes/diadospais/foto74.jpeg",
      "/img/exposicoes/diadospais/foto75.jpeg",
      "/img/exposicoes/diadospais/foto76.jpeg",
      "/img/exposicoes/diadospais/foto77.jpeg",
      "/img/exposicoes/diadospais/foto78.jpeg",
      "/img/exposicoes/diadospais/foto79.jpeg",
      "/img/exposicoes/diadospais/foto80.jpeg",
      "/img/exposicoes/diadospais/foto81.jpeg",
      "/img/exposicoes/diadospais/foto82.jpeg",
      "/img/exposicoes/diadospais/foto86.jpeg",
      "/img/exposicoes/diadospais/foto87.jpeg",
      "/img/exposicoes/diadospais/foto88.jpeg",
      "/img/exposicoes/diadospais/foto89.jpeg",
      "/img/exposicoes/diadospais/foto9.jpeg",
      "/img/exposicoes/diadospais/foto90.jpeg",
      "/img/exposicoes/diadospais/foto91.jpeg",
      "/img/exposicoes/diadospais/foto92.jpeg",
    ],
  }),
  computed: {
    base_url() {
      return this.$store.state.base_url;
    },
  },
  methods: {
    openModal(data = null, i) {
      if (data) {
        this.imagesBox = [];
        data.forEach((element) => {
          let newSrc;
          newSrc = this.base_url + element;
          this.imagesBox.push(newSrc);
        });
        this.index = i;
      }
    },
  },
};
</script>

<style>
.page-exhibition-fathersday {
  background: url(/img/exposicoes/diadospais/bg.png);
}
.page-exhibition-fathersday-winners {
  display: grid;
  gap: 60px;
  max-width: 900px;
  margin: 0 auto;
  grid-template:
    ". first first ."
    "second second third third";
  justify-content: center;
  align-content: center;
}

.page-exhibition-fathersday-winners > :nth-child(1) {
  grid-area: first;
}
.page-exhibition-fathersday-winners > :nth-child(2) {
  grid-area: second;
}
.page-exhibition-fathersday-winners > :nth-child(3) {
  grid-area: third;
}

.page-exhibition-fathersday-winners picture {
  position: relative;
}

.page-exhibition-fathersday-winners picture::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -7%;
  left: -4%;
  height: 120%;
  width: 108%;
  background: url(/img/exposicoes/diadospais/moldura.svg);
  background-repeat: no-repeat;
}

.page-exhibition-fathersday-winners picture::before {
  display: block;
  position: absolute;
  color: #ca4000;
  z-index: 99;
  top: -11%;
  left: 45%;
  font-size: 34px;
  font-weight: bold;
}

.page-exhibition-fathersday-winners > :nth-child(1) picture::before {
  content: "1";
}
.page-exhibition-fathersday-winners > :nth-child(2) picture::before {
  content: "2";
}
.page-exhibition-fathersday-winners > :nth-child(3) picture::before {
  content: "3";
}

.page-exhibition-fathersday-winners img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 11;
}
.page-exhibition-fathersday-list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, 240px);
  justify-content: center;
}

.page-exhibition-fathersday-list img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 12;
}
@media screen and (max-width: 600px) {
  .page-exhibition-fathersday-winners {
    display: grid;
    gap: 20px;
  }

  .page-exhibition-fathersday-winners picture::before {
    display: block;
    position: absolute;
    color: #ca4000;
    z-index: 99;
    top: -14%;
    left: 45%;
    font-size: 18px;
    font-weight: bold;
  }

  .page-exhibition-fathersday-list {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }
}
</style>