<template>
  <v-card>
    <v-card-title class="title-recents">
      <div class="col-10">Mais recentes</div>
      <div class="d-flex justify-end">
        <v-icon color="white">mdi-bookmark</v-icon>
      </div>
    </v-card-title>
    <div class="mx-2 mb-3">
      <v-card
        v-for="(item, i) in recents.slice(0, 5)"
        :key="i"
        :to="baseTo + item.url_pag"
        class="card-recents mb-3"
        width="100%"
        flat
      >
        <v-img
          v-if="!item.link"
          class="mr-2"
          width="80px"
          height="80px"
          cover
          :src="base_url + item.img"
        ></v-img>
        <v-img
          v-else
          class="mr-2"
          width="80px"
          height="80px"
          cover
          :src="thumb(item.link)"
        ></v-img>
        <v-card-title
          class="pa-0 release-title"
          v-html="item.title"
        ></v-card-title>
      </v-card>
    </div>
    <v-row justify="end" class="px-5 pb-2">
      <v-btn small v-if="btnViewMore" color="green" :to="viewMoreUrl" text
        >Ver mais</v-btn
      >
    </v-row>
  </v-card>
</template>

<script>
import { getIdFromURL } from "vue-youtube-embed";

export default {
  props: {
    recents: Array,
    btnViewMore: Boolean,
    viewMoreUrl: String,
    baseTo: String,
  },
  computed: {
    base_url() {
      return this.$store.state.base_url;
    },
  },
  methods: {
    thumb(url) {
      return `https://img.youtube.com/vi/${getIdFromURL(url)}/0.jpg`;
    },
  },
};
</script>

<style scoped>
.title-recents {
  background-color: grey;
  padding: 10px 0 10px 14px;
  margin-bottom: 15px;
  color: white;
}

.text-recents {
  padding-top: 10px;
  max-height: 80px;
  overflow: hidden;
}

.release-title {
  max-height: 70px;
  padding: 0px;
  font-size: 0.9rem;
  line-height: 20px;
  overflow: hidden;
  word-break: break-word;
}

.release-text {
  font-size: 0.8rem;
  overflow: hidden;
}

.card-recents {
  max-height: 100px;
  display: grid;
  grid-template-columns: max-content auto;
}

@media (min-width: 900px) {
  .card-text-container {
    padding-left: 30px;
  }
}

@media (max-width: 942px) {
  .row-recents {
    padding-left: 10px !important;
  }
}
</style>