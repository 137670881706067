<template>
  <v-row
    v-if="structure && structure.length > 0"
    justify="center"
    class="pt-5 pb-10"
  >
    <v-col cols="12" sm="10" md="9">
      <v-row class="col-12 title-card" justify="start">
        <div class="title-structure" v-html="capa.title"></div>
      </v-row>
      <v-row>
        <v-col cols="12" sm="5" class="col-img">
          <v-card elevation="4">
            <v-img
              width="100%"
              height="390px"
              max-height="400px"
              :src="base_url + capa.img"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-col>

        <v-col cols="12" sm="7" class="col-structure">
          <v-expansion-panels accordion focusable>
            <v-expansion-panel v-for="(item, i) in structure" :key="i">
              <div v-if="item.complemento !== 'capa'">
                <v-expansion-panel-header>
                  <v-row>
                    <v-col
                      cols="10"
                      class="structure-header d-flex justify-start"
                    >
                      <strong v-html="item.title"></strong>
                    </v-col>
                    <v-col class="structure-header d-flex justify-end pr-5">
                      <v-icon color="#217b43"
                        >mdi-{{ item.complemento }}</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="mt-2" v-html="item.text"></div>
                </v-expansion-panel-content>
              </div>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Structure",
  data: () => ({}),
  computed: {
    structure() {
      return this.$store.state.structure;
    },
    base_url() {
      return this.$store.state.base_url;
    },
    capa() {
      return this.structure.find((element) => {
        if (element.complemento == "capa") return true;
      });
    },
  },
};
</script>

<style  scoped>
.col-structure {
  padding-top: 20px;
  margin: 0px 0px 0px 0px;
}

.structure-header {
  padding: 0px !important;
}

.title-structure {
  font-size: 1.9rem;
  padding: 20px;
}

.col-img {
  padding: 20px;
  width: 100%;
}
</style>