<template>
  <div class="px-4 py-5 my-5">
    <div class="title-simple-page">
      <h2>
        <b v-html="title"></b>
      </h2>
    </div>
    <div class="page-staff-list justify-center">
      <doctor-card
        v-for="(doctor, i) in staff"
        :key="i"
        :doctor="doctor"
      ></doctor-card>
    </div>
  </div>
</template>

<script>
import DoctorCard from "@/components/cards/Doctor";

const staff = {
  "/equipe/unidade-clinica": {
    title: "Unidade Clínica",
    state: "clinicalUnit",
  },
  "/equipe/servico-de-anestesiologia": {
    title: "Serviço de Anestesiologia",
    state: "anesthesiologyService",
  },
  "/equipe/plantao-clinico": {
    title: "Plantão Clínico",
    state: "clinicalDuty",
  },
  "/equipe/parceiros": {
    title: "Parceiros",
    state: "partners",
  },
};

export default {
  data() {
    return staff[this.$route.path] || {};
  },
  computed: {
    staff() {
      return this.$store.state[this.state];
    },
  },
  components: {
    DoctorCard,
  },
};
</script>

<style>
.page-staff-list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, 300px);
}
</style>