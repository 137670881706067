<template>
  <v-dialog v-if="simplePage && simplePage.title" persistent v-model="viewed" max-width="1000px">
    <v-card width="100%">
      <v-btn
        class="pa-0"
        fab
        right
        absolute
        elevation="0"
        small
        color="transparent"
        dark
        @click="changeViewed"
      >
        <v-icon class="pa-0" color="red">mdi-close</v-icon>
      </v-btn>
      <!-- <v-card-actions class="d-flex justify-end">
        
      </v-card-actions>-->
      <v-container>
        <v-card-title class="d-flex justify-center pb-0">{{simplePage.title}}</v-card-title>
        <v-row justify="center" class="pa-6">
          <v-img max-height="250px" :src="base_url+simplePage.img"></v-img>
        </v-row>
        <v-card-text class="text-justify" v-html="simplePage.text"></v-card-text>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    viewedOrigin: true,
  }),
  props: {
    viewed: Boolean,
    urlModal: String,
  },
  methods: {
    changeViewed() {
      this.$emit("changeViewed", this.viewedOrigin);
    },
  },
  computed: {
    simplePage() {
      return this.$store.state.simplePage
    },
    base_url() {
      return this.$store.state.base_url
    },
  },
};
</script>

<style>
</style>