<template>
  <div>
    <v-container v-if="!small">
      <v-hover v-slot:default="{ hover }">
        <v-card
          class="mb-3"
          :elevation="hover ? 12 : 0"
          ripple
          :to="baseTo + item.url_pag"
        >
          <v-img
            class="img-card"
            height="220px"
            :src="base_url + item.img"
          ></v-img>
        </v-card>
      </v-hover>
      <v-row justify="center">
        <v-col v-html="item.title" class="d-flex justify-center"></v-col>
      </v-row>
    </v-container>
    <v-card v-else elevation="0" ripple :to="baseTo + item.url_pag">
      <v-img class="img-card" height="220px" :src="base_url + item.img"></v-img>
      <h4>{{ item.title }}</h4>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    small: Boolean,
    baseTo: String,
  },
  computed: {
    base_url() {
      return this.$store.state.base_url;
    },
  },
};
</script>

<style>
@media (max-width: 480px) {
  .img-card {
    height: 150px !important;
  }
}
</style>
