<template>
  <TabMenu :bgColor="true" :items="menu" :image="logo" :baseTo="'/maternidade/'" />
</template>

<script>

import TabMenu from '@/components/TabMenu'

export default {
  name: 'Maternity',
  components: {
    TabMenu
  },
  data() {
    return {
      logo: '/img/maternity.png',
      active: null,
    }
  },
  methods: {
    next() {
      const active = parseInt(this.active)
      this.active = (active < 2 ? active + 1 : 0)
    }
  },
  computed: {
    menu() {
      return this.$store.state.menuMaternity
    },
    base_url() {
      return this.$store.state.base_url
    },
  }
}
</script>

<style >
</style>