<template>
  <v-card class="overflow-hidden">
    <v-app-bar class="menu-bar" fixed height="85px" color="white">
      <v-toolbar-title class="col-10 col-sm-10 col-md-2 col-lg-4">
        <router-link class="mx-auto mx-md-0 logo-content" to="/">
          <img class="logo" :src="logo" width="200px" />
        </router-link>
      </v-toolbar-title>
      <div v-if="!$vuetify.breakpoint.smAndDown" class="d-flex justify-center">
        <div v-for="(item, i) in menu" :key="i">
          <v-menu v-if="item.childs" open-on-hover offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                elevation="0"
                active-class="active"
                class="button-menu"
                small
                v-on="on"
                >{{ item.name }}</v-btn
              >
            </template>

            <v-list>
              <v-list-item
                v-for="(child, i) in item.childs"
                :key="i"
                :to="child.path"
              >
                <v-list-item-title>{{ child.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            elevation="0"
            small
            v-if="!item.img && !item.childs"
            :to="item.path"
            v-on="on"
            class="button-menu"
            active-class="active"
            >{{ item.name }}</v-btn
          >
          <a class="icon-40" v-if="item.img" :href="item.path" target="_blank">
            <img :src="item.img" height="57px" />
          </a>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-subheader>
        <div class="d-flex justify-start">MENU</div>
      </v-subheader>
      <template v-for="(route, i) in menu">
        <v-list-item-group v-if="!route.img" v-model="current" :key="i">
          <v-list-item v-if="!route.childs">
            <v-list-item-title>
              <router-link class="link-menu-app" :to="route.path" replace>{{
                route.name
              }}</router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-group v-else>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ route.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              class="ml-4"
              v-for="(admin, i) in route.childs"
              :key="i"
              :to="admin.path"
              replace
            >
              <v-list-item-title>{{ admin.name }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-item-group>
      </template>
      <div class="d-flex flex-column align-center mt-4">
        <template v-for="(item, i) in menu">
          <div class="mt-2 ml-2" v-if="item.img" :key="i">
            <a class="icon-40" :href="item.path" target="_blank">
              <img :src="item.img" height="57px" />
            </a>
          </div>
        </template>
      </div>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  mounted() {
    this.menu.forEach((item) => {
      if (
        this.menuSocial &&
        this.menuSocial.length > 0 &&
        this.menuProjects &&
        this.menuProjects.length > 0
      ) {
        switch (item.name) {
          case "Social":
            item.childs = this.menuSocial;
            break;
          case "Projetos":
            item.childs = this.menuProjects;
            break;
          case "Serviços":
            item.childs = this.menuServices;
            break;
          case "Imprensa":
            item.childs = this.pressRoom;
            break;
          default:
            break;
        }
      }
    });
  },
  data() {
    return {
      on: null,
      logo: "/img/logo.png",
      drawer: false,
      menu: [
        { path: "/", name: "Início" },
        { path: "/sobre", name: "Sobre nós" },
        {
          name: "Imprensa",
          childs: [
            { path: "/releases", name: "Releases e Notícias" },
            { path: "/videos", name: "Vídeos" },
            { path: "/fotos-e-eventos", name: "Fotos e Eventos" },
          ],
        },
        {
          name: "Social",
          childs: [],
        },
        {
          name: "Projetos",
          childs: [],
        },
        {
          path: "/atendimento",
          name: "Atendimento",
        },
        {
          name: "Serviços",
          childs: [
            { path: "/service/internacoes-115", name: "Internações" },
            { path: "/service/centro-cirurgico-116", name: "Centro Cirúrgico" },
            {
              path: "/service/centro-de-especialidades-117",
              name: "Centro de Especialidades",
            },
            { path: "/service/emergencia-118", name: "Emergência" },
          ],
        },
        {
          path: "https://exames.hospitaldoispinheiros.com.br:8443/Mediweb/login",
          img: "/img/exames.png",
        },
        {
          path: "http://40anos.hospitaldoispinheiros.com.br/",
          img: "/img/icone2.png",
        },
      ],
    };
  },
  computed: {
    current: {
      get() {
        return this.$route.path;
      },
      set() {},
    },
    menuSocial() {
      return this.$store.state.menuSocial;
    },
    menuServices() {
      return this.$store.state.menuServices;
    },
    menuProjects() {
      return this.$store.state.menuProjects;
    },
    pressRoom() {
      return this.$store.state.pressRoom;
    },
  },
  methods: {
    toLink(link) {
      this.$route.replace({ path: link });
    },
  },
  watch: {
    menuSocial: function (value) {
      if (value) {
        this.menu.forEach((item) => {
          if (item.name === "Social") {
            item.childs = this.menuSocial;
          }
        });
      }
    },
    menuServices: function (value) {
      if (value) {
        this.menu.forEach((item) => {
          if (item.name === "Serviços") {
            item.childs = this.menuServices;
          }
        });
      }
    },
    menuProjects: function (value) {
      if (value) {
        this.menu.forEach((item) => {
          if (item.name === "Projetos") {
            item.childs = this.menuProjects;
          }
        });
      }
    },
    pressRoom: function (value) {
      if (value) {
        this.menu.forEach((item) => {
          if (item.name === "Imprensa") {
            item.childs = this.pressRoom;
          }
        });
      }
    },
  },
};
</script>

<style>
.menu-bar {
  z-index: 999 !important;
}
.button-menu {
  margin-right: 10px;
  margin-top: 25px;
  margin-left: 7px;
  background-color: transparent !important;
  text-transform: none !important;
  font-size: 0.8em !important;
}
.active {
  color: white !important;
  background-color: #217b43 !important;
}

.icon-40 {
  margin-left: 10px;
  margin-top: 10px;
}

.overflow-hidden {
  padding-bottom: 80px;
}

.logo {
  padding: 30px;
}

@media (min-width: 770px) {
  .logo-content {
    display: flex;
    justify-content: center;
  }
}

.itemm {
  padding-right: 40px !important;
}

.list-item {
  padding-left: 20px !important;
}

.item-template {
  padding-left: 0px !important;
}

.link-menu-app {
  text-decoration: none !important;
}

.v-toolbar__content,
.v-toolbar__extension {
  z-index: 9999 !important;
}
.v-navigation-drawer {
  z-index: 9999 !important;
}
</style>