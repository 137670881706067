<template>
  <v-card
    class="pa-0"
    elevation="0"
    v-if="maternityCalendar && maternityCalendar.length > 0"
  >
    <v-tabs
      v-model="tab"
      show-arrows
      background-color="purple lighten-2"
      color="white"
      icons-and-text
    >
      <v-tab v-for="(item, i) in weeks" :key="i">
        Semana
        <div v-html="item.title"></div>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(item, i) in maternityCalendar"
        :key="i"
        class="py-5 px-0"
      >
        <v-card flat>
          <v-card-text class="px-0" v-html="item.text"></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      weeks: [
        { title: "1ª", content: "Tab 1 Content" },
        { title: "2ª", content: "Tab 2 Content" },
        { title: "3ª", content: "Tab 3 Content" },
        { title: "4ª", content: "Tab 4 Content" },
        { title: "5ª", content: "Tab 5 Content" },
        { title: "6ª", content: "Tab 6 Content" },
        { title: "7ª", content: "Tab 7 Content" },
        { title: "8ª", content: "Tab 8 Content" },
        { title: "9ª", content: "Tab 9 Content" },
        { title: "10ª", content: "Tab 10 Content" },
        { title: "11ª", content: "Tab 10 Content" },
        { title: "12ª", content: "Tab 10 Content" },
        { title: "13ª", content: "Tab 10 Content" },
        { title: "14ª", content: "Tab 10 Content" },
        { title: "15ª", content: "Tab 10 Content" },
        { title: "16ª", content: "Tab 10 Content" },
        { title: "17ª", content: "Tab 10 Content" },
        { title: "18ª", content: "Tab 10 Content" },
        { title: "19ª", content: "Tab 10 Content" },
        { title: "20ª", content: "Tab 10 Content" },
        { title: "21ª", content: "Tab 10 Content" },
        { title: "22ª", content: "Tab 10 Content" },
        { title: "23ª", content: "Tab 10 Content" },
        { title: "24ª", content: "Tab 10 Content" },
        { title: "25ª", content: "Tab 10 Content" },
        { title: "26ª", content: "Tab 10 Content" },
        { title: "27ª", content: "Tab 10 Content" },
        { title: "28ª", content: "Tab 10 Content" },
        { title: "29ª", content: "Tab 10 Content" },
        { title: "30ª", content: "Tab 10 Content" },
        { title: "31ª", content: "Tab 10 Content" },
        { title: "32ª", content: "Tab 10 Content" },
        { title: "33ª", content: "Tab 10 Content" },
        { title: "34ª", content: "Tab 10 Content" },
        { title: "35ª", content: "Tab 10 Content" },
        { title: "36ª", content: "Tab 10 Content" },
        { title: "37ª", content: "Tab 10 Content" },
        { title: "38ª", content: "Tab 10 Content" },
        { title: "39ª", content: "Tab 10 Content" },
        { title: "40ª", content: "Tab 10 Content" },
      ],
    };
  },
  computed: {
    maternityCalendar() {
      return this.$store.state.maternityCalendar;
    },
  },
};
</script>