<template>
  <v-container v-if="menu && menu.length > 0">
    <div v-if="logo" class="logo-institutional">
      <v-row justify="center" class="py-3">
        <v-img max-height="150px" max-width="300px" width="100%" :src="logo"></v-img>
      </v-row>
    </div>
    <v-row class="py-10 px-1">
      <v-card outlined width="100%">
        <v-expansion-panels hover flat>
          <v-expansion-panel
          >
            <v-expansion-panel-header class="py-0 px-1">
              <v-icon class="px-4" color="red">mdi-flower</v-icon>
              <div class="py-2 col-12 d-flex justify-center title">
                 <span>Calendário Gestacional</span>
              </div>
              <v-icon class="px-4" color="red">mdi-flower</v-icon>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Calendar/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-row>
    <v-tabs class="mb-8" show-arrows color="green">
      <v-tab
        class="tab-name"
        v-for="(item, i) in menu"
        :key="i"
        :to="item.url_pag"
      >
        {{item.title}}
      </v-tab>
    </v-tabs>
    <ItemTabPage :state="'contentMaternity'" :url="'getContentMaternity/'" />
  </v-container>
</template>

<script>
import ItemTabPage from '@/components/ItemTabPage'
import Calendar from '@/components/maternity/Calendar'

export default {
  components: {
    Calendar,
    ItemTabPage
  },
  data: () => ({
    logo: '/img/maternity.png',
  }),
  computed: {
    menu() {
      return this.$store.state.menuMaternity
    },
    base_url() {
      return this.$store.state.base_url
    },
  }
}
</script>

<style scoped>
</style>