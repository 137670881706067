<template>
  <v-card
    class="widget-staff-category-card mx-auto"
    :to="to"
    width="240"
    height="180"
    flat
  >
    <img class="widget-staff-category-card-img pb-4" :src="icon" />
    <v-card-title
      class="py-1 d-flex justify-center text-body-1 font-weight-bold"
      v-html="title"
    >
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    to: {},
  },
};
</script>

<style>
.widget-staff-category-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  width: 240px;
}

.widget-staff-category-card-img {
  object-fit: contain;
  width: 120px;
  height: 120px;
}
</style>