<template>
  <v-row v-if="history && history.title">
    <v-card elevation="0" width="100%" class="d-flex justify-center">
      <v-container class="container-history col-12 col-sm-9">
        <v-row class="col-12" justify="start">
    
            <div class="title-history">{{history.title}}</div>
        
        </v-row>
        <v-row justify="center">
          <v-col cols="11" sm="9" md="5">
            <v-card>
              <v-img height="100%" width="100%" max-height="300px" :src="base_url+history.img">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-col>
          <v-col cols="11" sm="9" md="7">
            <v-card class="text-history overflow-y-auto">
              <div v-html="history.text"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-row>
</template>

<script>
export default {
  computed: {
    history() {
      return this.$store.state.aboutHistory
    },
    base_url() {
      return this.$store.state.base_url
    }
  },
  name: 'History',
}
</script>

<style scoped>
.title-history {
  font-size: 1.7rem;
  font-weight: bold;
}

.subtitle-history {
  font-size: 1.7rem;
}

.container-history {
  padding: 10px;
}

.text-history {
  max-height: 300px;
  padding: 10px;
}

.text-history::-webkit-scrollbar-track {
  background-color: #f4f4f4 !important;
}

.text-history::-webkit-scrollbar {
  width: 3px;
  background: #f4f4f4 !important;
}

.text-history::-webkit-scrollbar-thumb {
  background: #217b43 !important;
}
.col-history-text {
  padding: 10px 0;
}
</style>