<template>
  <div>
    <ToolBar />
    <v-container>
      <h3>Fotos e Eventos</h3>
      <v-row v-if="photos && photos.length > 0" justify="center">
        <v-col cols="6" sm="4" v-for="(item,i) in photos" :key="i">
          <CardPhotos :baseTo="'/fotos-e-eventos/'" :item="item" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import CardPhotos from '@/components/press/CardPhotos'
import ToolBar from '@/components/ToolBar'

export default {
  components: {
    CardPhotos,
    ToolBar
  },
  computed: {
    photos() {
      return this.$store.state.photos
    },
    base_url() {
      return this.$store.state.base_url
    }
  }
}
</script>

<style>
</style>