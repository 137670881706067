<template>
  <v-container v-if="menu && menu.length > 0">
    <div v-if="logo" class="logo-institutional">
      <v-row justify="center" class="py-3">
        <v-img
          max-height="150px"
          max-width="300px"
          width="100%"
          :src="logo"
        ></v-img>
      </v-row>
    </div>
    <v-tabs class="mb-8" show-arrows color="#217b43">
      <v-tab
        class="tab-name"
        v-for="item in menu"
        :key="item.id"
        @click="removeExams(item)"
        v-html="item.title"
      >
      </v-tab>
      <v-tab
        class="tab-name"
        v-for="(item, i) in exams"
        :key="i"
        @click="renderExam(item)"
        v-html="item.title"
      >
      </v-tab>
      <v-tab-item class="pt-5">
        <v-card v-if="about && about.title" class="pa-4 pt-10" elevation="0">
          <v-img
            v-if="about.image"
            max-height="350px"
            width="100%"
            height="100%"
            :src="base_url + about.image"
          ></v-img>
          <v-card-text
            class="text-about pa-0"
            v-html="about.text"
          ></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <ExamsItem :exam="exam" />
  </v-container>
</template>

<script>
import ExamsItem from "./ExamsItem";

export default {
  components: {
    ExamsItem,
  },
  data: () => ({
    exam: null,
    aboutData: null,
    logo: "/img/logo-diagn-img.png",
    menu: [{ id: 55, title: "Sobre", url_pag: "/clinica-imagem/sobre" }],
  }),
  methods: {
    renderExam(exam) {
      this.exam = exam;
    },
    removeExams() {
      this.exam = "";
    },
  },
  computed: {
    base_url() {
      return this.$store.state.base_url;
    },
    exams() {
      return this.$store.state.examsClinicImage;
    },
    about() {
      return this.$store.state.aboutClinicImage;
    },
  },
};
</script>

<style scoped>
</style>