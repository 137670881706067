<template>
  <vue-flux
    class="page-home-slideshow black"
    :options="vfOptions"
    :images="vfImages"
    :transitions="vfTransitions"
    :captions="vfCaptions"
    ref="slider"
  >
    <template #controls>
      <div class="d-flex justify-space-between mx-4">
        <v-btn
          @click="() => $refs.slider.show('prev')"
          color="white"
          icon="icon"
        >
          <v-icon size="26">fas fa-chevron-left</v-icon>
        </v-btn>
        <v-btn
          @click="() => $refs.slider.show('next')"
          color="white"
          icon="icon"
        >
          <v-icon size="26">fas fa-chevron-right</v-icon>
        </v-btn>
      </div>
    </template>
    <template #caption>
      <flux-caption v-slot="captionProps">
        <a
          v-if="captionProps.caption.url"
          :href="captionProps.caption.url"
          class="flux-caption"
          target="_blank"
        ></a>
      </flux-caption>
    </template>
  </vue-flux>
</template>

<script>
import { VueFlux, FluxCaption } from "vue-flux";

export default {
  data: () => ({
    vfOptions: {
      autoplay: true,
      aspectRatio: "190:71",
    },
    vfTransitions: ["zip"],
  }),
  computed: {
    slides() {
      return this.$store.state.slides || [];
    },
    vfImages() {
      return this.slides.map(({ img }) => this.base_url + img);
    },
    vfCaptions() {
      return this.slides.map(({ link }) => ({ url: link }));
    },
    base_url() {
      return this.$store.state.base_url;
    },
  },
  components: {
    VueFlux,
    FluxCaption,
  },
};
</script>

<style>
.v-carousel .v-carousel__controls__item i {
  font-size: 14px !important;
}

.page-home-slideshow div.flux-caption {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0 !important;
}

.page-home-slideshow a.flux-caption {
  height: 100%;
  width: 100%;
  display: block;
}
</style>