<template>
  <v-row v-if="directors.length" class="py-5">
    <v-card elevation="0" width="100%" class="d-flex justify-center">
      <v-container class="container-history col-12 col-sm-9">
        <v-row class="col-12" justify="center">
          <div class="page-about-directors-title">Diretoria</div>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <div class="page-about-directors-list justify-center">
              <DoctorCard
                v-for="(doctor, i) in directors"
                :key="i"
                :doctor="doctor"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-row>
</template>

<script>
import DoctorCard from "@/components/cards/Doctor";

export default {
  computed: {
    directors() {
      return this.$store.state.directors;
    },
    base_url() {
      return this.$store.state.base_url;
    },
  },
  beforeCreate() {
    this.$store.dispatch("request", {
      state: "directors",
      url: "getAllDirectors",
    });
  },
  components: {
    DoctorCard,
  },
};
</script>

<style>
.page-about-directors-list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, 300px);
}

.page-about-directors-title {
  font-size: 1.7rem;
  font-weight: bold;
}
</style>