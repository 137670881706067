<template>
  <div>
    <ToolBar title="Videos" />
    <v-container>
      <h3>Vídeos</h3>
      <v-row justify="start">
        <v-col class="pa-0" cols="4" sm="3" v-for="(item,i) in videos" :key="i">
          <CardVideo :baseTo="'/video/'" :item="item" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import CardVideo from '@/components/press/CardVideo'
import ToolBar from '@/components/ToolBar'

export default {
  components: {
    CardVideo,
    ToolBar
  },
  computed: {
    videos() {
      return this.$store.state.videos
    },
    base_url() {
      return this.$store.state.base_url
    }
  }
}
</script>

<style>
</style>