<template>
  <div>
    <v-footer class="d-flex justify-center" color="#217b43">
      <div class="col-sm-12 col-md-6">
        <div class="d-flex justify-center mb-5 mt-5">
          <img src="/img/landline.png" />
        </div>
        <div class="emergency d-flex justify-center">EM CASO DE EMERGÊNCIA LIGUE</div>
        <div class="phone d-flex justify-center">{{phone}}</div>
        <v-row>
          <v-img src="/img/curve.png" width="100%"></v-img>
        </v-row>
      </div>
      <v-row class="col-lg-8 col-xl-8">
        <v-col class="col-12 col-sm-6 col-md-3" v-for="link in menu" :key="link.name">
          <div class="title-footer">
            <span class="title-footer-child">{{ link.name }}</span>
          </div>
          <v-row v-for="item in link.childs" :key="item.name">
            <router-link class="items" :to="item.path">
              <span v-if="item.iconDefault">
                <v-icon color="white">{{item.iconDefault}}</v-icon>
              </span>
              <span v-else>&rarr;</span>
              {{item.name}}
            </router-link>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
    <v-row class="copy">
      <div class="copy-text">{{copy}}</div>
    </v-row>
  </div>
</template>

<script>
export default {
  mounted() {
    this.menu.forEach((item) => {
      if (
        this.menuSocial &&
        this.menuSocial.length > 0 &&
        this.menuProjects &&
        this.menuProjects.length > 0
      ) {
        switch (item.name) {
          case "Social":
            item.childs = this.menuSocial;
            break;
          case "Projetos":
            item.childs = this.menuProjects;
            break;
          case "Serviços":
            item.childs = this.menuServices;
            break;
          default:
            break;
        }
      }
    });
  },
  data() {
    return {
      logo: "/img/favicon-white.svg",
      menu: [
        {
          name: "Social",
          childs: [],
        },
        {
          name: "Projetos",
          childs: [],
        },
        {
          name: "Serviços",
          childs: [],
        },
        {
          childs: [
            {
              iconDefault: "mdi-google-maps",
              name: "Avenida dos Tarumãs, 995 Centro - Sinop",
              path: "/atendimento",
            },
            {
              iconDefault: "mdi-phone-in-talk",
              name: "(+55) 66 3517 2600",
              path: "/atendimento",
            },
          ],
          name: "Atendimento",
        },
      ],
      phone: "(66) 3517 2600",
      copy: "© 2020 Hospital Dois Pinheiros - Todos os direitos reservados.",
    };
  },
  computed: {
    menuSocial() {
      return this.$store.state.menuSocial;
    },
    menuServices() {
      return this.$store.state.menuServices;
    },
    menuProjects() {
      return this.$store.state.menuProjects;
    },
  },
  watch: {
    menuSocial: function (value) {
      if (value) {
        this.menu.forEach((item) => {
          if (item.name === "Social") {
            item.childs = this.menuSocial;
          }
        });
      }
    },
    menuServices: function (value) {
      if (value) {
        this.menu.forEach((item) => {
          if (item.name === "Serviços") {
            item.childs = this.menuServices;
          }
        });
      }
    },
    menuProjects: function (value) {
      if (value) {
        this.menu.forEach((item) => {
          if (item.name === "Projetos") {
            item.childs = this.menuProjects;
          }
        });
      }
    },
  },
};
</script>

<style>
.title-footer {
  margin-right: 5%;
  margin-bottom: 20px;
  border-bottom: 1px solid grey;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.title-footer-child {
  border-bottom: solid 2px white;
  padding-bottom: 2px;
}

.items {
  text-decoration: none !important;
  font-size: 0.9rem;
  margin-left: 10px;
  margin-bottom: 6px;
  color: white !important;
}

.emergency {
  color: white;
  font-weight: bolder;
  font-size: 0.8rem;
}

.phone {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}

.copy {
  display: flex;
  justify-content: center;
  height: 65px;
  background-color: #373e4c;
}

.copy-text {
  padding: 10px;
  margin: 10px;
  font-size: 0.8rem;
  color: white;
}

body {
  overflow: hidden !important;
}
</style>