<template>
  <div>
    <ToolBar />
    <v-container class="mb-8">
      <h3>Exposições</h3>
      <v-row v-if="items && items.length > 0">
        <v-col cols="6" sm="3" v-for="(item, i) in items" :key="i">
          <ExhibitionCard :baseTo="'/exposicoes/'" :item="item" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ExhibitionCard from "@/components/cards/Exhibition";
import ToolBar from "@/components/ToolBar";

export default {
  components: {
    ExhibitionCard,
    ToolBar,
  },
  computed: {
    items() {
      return [
        {
          url_pag: "diadospais",
          img: "/img/exposicoes/diadospais/cover.png",
          title: "Dia dos Pais",
        },
      ];
    },
  },
};
</script>

<style>
</style>