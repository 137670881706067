<template>
  <div class="pt-10">
    <div class="history">
      <History />
    </div>
    <div class="who-we-are">
      <v-row v-if="about && about.title">
        <v-card
          elevation="0"
          width="100%"
          class="d-flex justify-center card-who"
        >
          <div class="container-about col-12 col-sm-9">
            <v-row class="col-12" justify="start">
              <v-col cols="auto">
                <div
                  v-if="about"
                  v-html="about.title"
                  class="title-about"
                ></div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="11" sm="12" md="7" class="text-about">
                <div v-html="about.text"></div>
              </v-col>
              <v-col class="col-img-who" cols="12" sm="11" md="5">
                <v-card elevation="4">
                  <v-img
                    height="100%"
                    width="100%"
                    max-height="300px"
                    :src="base_url + about.img"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-row>
    </div>
    <div>
      <Directors />
    </div>
    <v-divider></v-divider>
    <div class="clinic-body">
      <ClinicBody class="container" :title="'Conheça Nossos Especialistas'" />
    </div>
    <div class="structure">
      <Structure />
    </div>
    <div class="guide">
      <Guide />
    </div>
    <div class="waste" v-if="waste && waste.title">
      <v-row justify="center">
        <v-card class="col-12 col-sm-9 mb-10">
          <v-col>
            <v-row justify="center" class="d-flex justify-center">
              <div class="title-waste" v-html="waste.title"></div>
            </v-row>
            <v-row justify="center">
              <v-col cols="12">
                <div class="text-waste" v-html="waste.text"></div>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-row>
    </div>
  </div>
</template>

<script>
import History from "./History";
import ClinicBody from "@/components/widgets/clinical-body/Index.vue";
import Structure from "./Structure";
import Guide from "./Guide";
import Directors from "./Directors";

export default {
  name: "About",
  computed: {
    about() {
      return this.$store.state.aboutWhoWe;
    },
    waste() {
      return this.$store.state.waste;
    },
    base_url() {
      return this.$store.state.base_url;
    },
  },
  components: {
    History,
    ClinicBody,
    Structure,
    Guide,
    Directors,
  },
};
</script>

<style scoped>
.history {
  margin-bottom: 50px;
}
.clinic-body {
  margin-bottom: 80px;
}
.title-waste {
  font-size: 1.9rem;
  padding-bottom: 5px;
}

.text-waste {
  font-size: 1.1rem;
}

.title-about {
  font-size: 1.7rem;
  font-weight: bold;
}

.subtitle-about {
  font-size: 1.7rem;
}

.container-about {
  padding: 10px;
}

.text-about {
  padding: 10px 0 0 25px;
}

.card-who {
  background-color: #f5f5f5 !important;
}

.structure {
  background-color: #f5f5f5 !important;
}
.col-img-who {
  padding: 0 20px;
}
</style>