<template>
  <v-sheet class="bg-press-room d-flex align-center flex-column">
    <v-row class="center-especiality" v-if="title" v-html="title"></v-row>
    <v-row
      v-else
      class="d-flex justify-start ml-12 pl-5 center-especiality text-center"
    >
      <strong>Conheça Nossos Especialistas</strong>
    </v-row>
    <div class="widget-clinical-body-container pt-10">
      <category-card
        :icon="files + 'img/staff/clinic-unity.png'"
        to="/equipe/unidade-clinica"
        title="Unidade Clínica"
      ></category-card>
      <category-card
        :icon="files + 'img/staff/anesthesiology-service.png'"
        to="/equipe/servico-de-anestesiologia"
        title="Serviço de Anestesiologia"
      ></category-card>
      <category-card
        :icon="files + 'img/staff/clinical-duty.png'"
        to="/equipe/plantao-clinico"
        title="Plantão Clínico"
      ></category-card>
      <category-card
        :icon="files + 'img/staff/partners.png'"
        to="/equipe/parceiros"
        title="Parceiros"
      ></category-card>
      <category-card
        :icon="files + 'img/staff/imaging-clinic-team.png'"
        to="/equipe/clinica-de-imagem"
        title="Clínica de Imagem"
      ></category-card>
    </div>
  </v-sheet>
</template>

<script>
import CategoryCard from "./CategoryCard";
import { mapState } from "vuex";

export default {
  props: {
    title: String,
  },
  computed: {
    files() {
      return this.$store.state.files;
    },
    ...mapState([
      "partners",
      "clinicalDuty",
      "anesthesiologyService",
      "clinicalUnit",
      "imagingClinicTeam",
    ]),
  },
  methods: {
    getImgs(state) {
      return state.map(({ image }) => this.files + image);
    },
  },
  components: {
    CategoryCard,
  },
};
</script>

<style scoped>
.widget-clinical-body-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

@media screen and (max-width: 1264px) {
  .widget-clinical-body-container {
    flex-wrap: wrap;
  }
}

.center-especiality {
  font-size: 1.9rem;
  margin-bottom: 20px;
  margin-top: 40px;
  padding: 0 80px;
}
</style>