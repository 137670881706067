<template>
  <v-card max-height="455px" class="card-doctor" elevation="0" :to="to">
    <div>
      <v-img
        class="rounded"
        v-if="doctor.image"
        height="280px"
        :src="base_url + doctor.image"
      >
      </v-img>
    </div>
    <v-card-title
      class="name-doctor"
      v-if="doctor.name"
      v-html="doctor.name"
    ></v-card-title>
    <v-card-subtitle
      class="title-doctor"
      v-if="doctor.cargo"
      v-html="doctor.cargo"
    ></v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  props: {
    doctor: Object,
    to: String,
  },
  computed: {
    base_url() {
      return this.$store.state.base_url;
    },
  },
};
</script>

<style scoped>
.hover-card:hover {
  cursor: default;
}

.social-web {
  color: white !important;
  margin: 5px;
}

.social-web:hover {
  cursor: pointer;
}

.v-card--reveal {
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  width: 100%;
}

.name-doctor {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1rem;
  color: #217b43;
  padding: 8px 10px 0px 10px;
}

.title-doctor {
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0 10px;
  margin-top: 0px;
}

.descrip-doctor {
  height: 80px;
  overflow: hidden;
  font-size: 0.8rem;
  padding: 0 10px;
  margin-top: 12px;
}

.card-doctor {
  width: calc(100% - 16px);
  height: 100%;
}
</style>