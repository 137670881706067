<template>
  <v-container>
    <v-hover v-slot:default="{ hover }">
      <div>
        <v-card
          class="pa-0"
          :elevation="hover ? 12 : 0"
          ripple
          :to="baseTo + item.url_pag"
        >
          <v-card-text class="pa-0">
            <v-img v-if="item.link" :src="thumb(item.link)"></v-img>
            <v-img v-else :src="item.image"></v-img>
          </v-card-text>
        </v-card>
        <v-row class="container">
          <v-col class="py-0" v-html="item.title"></v-col>
        </v-row>
      </div>
    </v-hover>
  </v-container>
</template>

<script>
import { getIdFromURL } from "vue-youtube-embed";

export default {
  props: {
    item: Object,
    small: Boolean,
    baseTo: String,
  },
  methods: {
    thumb(url) {
      return `https://img.youtube.com/vi/${getIdFromURL(url)}/0.jpg`;
    },
  },
};
</script>

<style>
</style>