<template>
  <div>
    <ToolBar />
    <v-container class="py-5 my-5">
      <div class="title-simple-page">
        <h2 class="">
          <b v-html="title"></b>
        </h2>
      </div>
      <div class="text-justify py-6" v-html="text"></div>
      <div class="my-5">
        <v-row>
          <v-col cols="6" sm="3" v-for="(item, i) in photos" :key="i">
            <v-hover v-slot:default="{ hover }">
              <v-card :elevation="hover ? 12 : 0">
                <v-img
                  height="200px"
                  width="100%"
                  class="image"
                  :src="base_url + item"
                >
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="
                        d-flex
                        transition-fast-in-fast-out
                        darken-2
                        v-card--reveal
                        display-3
                        white--text
                      "
                      style="height: 100%"
                    >
                      <v-btn small fab @click="openModal(photos, i)">
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </div>
                  </v-expand-transition>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <TinyBox :loop="true" v-model="index" :images="imagesBox" />
      </div>
    </v-container>
  </div>
</template>

<script>
import TinyBox from "vue-tinybox";
import ToolBar from "@/components/ToolBar";

export default {
  name: "SimplePage",
  components: {
    TinyBox,
    ToolBar,
  },
  data: () => ({
    index: null,
    imagesBox: [],
  }),
  props: {
    img: String,
    title: String,
    text: String,
    photos: Array,
  },
  methods: {
    openModal(data = null, i) {
      if (data) {
        this.imagesBox = [];
        data.forEach((element) => {
          let newSrc;
          newSrc = this.base_url + element;
          this.imagesBox.push(newSrc);
        });
        this.index = i;
      }
    },
  },
  computed: {
    base_url() {
      return this.$store.state.base_url;
    },
  },
};
</script>

<style>
.title-item {
  margin-right: 5%;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  width: 30px;
  border-bottom: solid 2px black;
}

h3::after {
  background-color: green;
}
</style>