<template>
  <v-sheet
    v-if="pressRoom && pressRoom.length > 0"
    class="bg-press-room d-flex align-center flex-column"
  >
    <div class="press-room">
      <v-row justify="center">
        Sala de Imprensa
        <strong class="ml-2">Dois Pinheiros</strong>
      </v-row>
    </div>
    <v-slide-group class="simple-slide" show-arrows>
      <v-slide-item
        class="card-press-content mx-2"
        v-for="(item, i) in pressRoom"
        :key="i"
        v-slot:default="{ toggle }"
      >
        <v-card
          @click="toggle"
          class="hover-press-room pb-4"
          :to="item.path"
          elevation="2"
        >
          <v-toolbar
            elevation="2"
            :src="base_url + item.img"
            height="220px"
            max-height="390px"
            width="100%"
            extended
          >
            <template v-slot:extension>
              <v-btn
                color="#217b43"
                class="icon-press"
                elevation="0"
                absolute
                bottom
                left
                fab
              >
                <v-icon color="white">{{ item.icon }}</v-icon>
              </v-btn>
            </template>
          </v-toolbar>
          <v-card-title class="title-press">{{ item.name }}</v-card-title>
          <v-card-text class="descrip-press" v-html="item.text"> </v-card-text>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      model: null,
      clockIcon: "/img/icons/clock.svg",
    };
  },
  computed: {
    pressRoom() {
      return this.$store.state.pressRoom;
    },
    base_url() {
      return this.$store.state.base_url;
    },
  },
};
</script>

<style scoped>
.bg-press-room {
  background-color: #f5f5f5 !important;
}
.card-press {
  width: calc(100% - 16px);
  height: 100%;
}

.name-press {
  font-size: 1.2rem;
  color: black;
  padding: 8px 0 0 4px !important;
}

.title-press {
  font-size: 1.3rem;
  font-weight: bold;
  padding-top: 35px;
  padding-bottom: 0;
}

.descrip-press {
  font-size: 1rem;
  max-height: 50px;
  overflow: hidden;
}

.press-room {
  font-size: 1.9rem;
  margin-bottom: 40px;
  padding-top: 80px;
}

.view-profile {
  text-transform: none !important;
  padding: 4px !important;
  background-color: grey;
}

.btn-comments {
  text-decoration: none;
}

.row-press-items {
  font-size: 0.9rem;
}

.icon-press {
  border: 7px solid white !important;
}
</style>
