<template>
  <v-container v-if="menu && menu.length > 0">
    <v-tabs class="mb-8" show-arrows color="#217b43">
      <v-tab
        class="tab-name"
        v-for="(item, i) in menu"
        :key="i"
        :to="item.url_pag"
      >
        {{item.title}}
      </v-tab>
    </v-tabs>
    <ItemTabPage :state="'contentEndoscopy'" :url="'getContentEndoscopy/'" />
  </v-container>
</template>

<script>
import ItemTabPage from '@/components/ItemTabPage'

export default {
  components: {
    ItemTabPage
  },
  computed: {
    menu() {
      return this.$store.state.endoscopyMenu
    },
    base_url() {
      return this.$store.state.base_url
    },
  }
}
</script>

<style scoped>
</style>