<template>
  <div v-if="exam && exam.title">
    <v-container >
      <v-card elevation="0">
        <v-card-title class="pa-2 py-5">
          <h3>{{exam.title}}</h3>
        </v-card-title>
        <v-row>
          <v-col class="py-0 px-4 pb-5" cols="12" sm="5">
            <v-img max-width="600px" height="100%" :src="base_url+exam.img"></v-img>
          </v-col>
          <v-col class="py-0" cols="12" sm="7">
            <v-card-text class="text-exam text-justify" v-html="exam.text"></v-card-text>
          </v-col>
        </v-row>
        <v-row class="pa-1 pt-5">
          <v-col cols="4" sm="2" v-for="(image, i ) in exam.imgs" :key="i">
            <v-hover v-slot:default="{ hover }">
              <v-card :elevation="hover ? 12 : 0" @click="openModal(exam.imgs, i)">
                <v-img width="100%" height="150px" max-width="300px" :src="base_url+image"></v-img>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <TinyBox :loop="true" v-model="index" :images="imagesBox" />
  </div>
</template>

<script>
import TinyBox from 'vue-tinybox';

export default {
  props: {
    exam: Object,
  },
  components: {
    TinyBox
  },
  data: () => ({
    index: null,
    imagesBox: []
  }),
  methods: {
    openModal(data = null, i) {
      if (data) {
        this.imagesBox = []   
        data.forEach(element => {
          let newSrc
          newSrc = this.base_url+element
          this.imagesBox.push(newSrc)
        });
        this.index = i
      }
    }
  },
  computed: {
    base_url() {
      return this.$store.state.base_url
    },
  },
  watch: {
    '$route.params.name': function () {
      this.$store.dispatch("request", {
        state: "examClinicImage",
        method: "POST",
        url: "getExam/"+this.$route.params.exame
      });
    }
  }
}
</script>

<style>
@media (min-width: 600px) {
  .text-exam {
    max-height: 350px;
    overflow-y: auto;
    font-size: 1rem;
  }

  .text-exam::-webkit-scrollbar-track {
    background-color: #f4f4f4 !important;
  }

  .text-exam::-webkit-scrollbar {
    width: 3px;
    background: #f4f4f4 !important;
  }

  .text-exam::-webkit-scrollbar-thumb {
    background: #217b43 !important;
  }
}
</style>