<template>
  <div class="pb-12">
    <ToolBar />
    <v-container v-if="video && video.title">
      <div class="px-5">
        <v-row>
          <v-col class="pa-0">
            <h3 class="pt-4" v-html="video.title"></h3>
            <v-hover v-slot:default="{ hover }">
              <v-card
                max-width="700px"
                class="d-flex justify-center pb-0 pt-2"
                :elevation="hover ? 12 : 0"
              >
                <youtube
                  :player-width="videoWidth"
                  player-height="360"
                  :video-id="videoId"
                ></youtube>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="4" class="pt-9 mt-8">
            <CardRecents
              :baseTo="'/video/'"
              :recents="releaseVideos"
              :btnViewMore="true"
              :viewMoreUrl="viewMoreUrl"
            />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import ToolBar from "@/components/ToolBar";
import CardRecents from "@/components/press/CardRecents";
import { getIdFromURL } from "vue-youtube-embed";

export default {
  mounted() {
    this.$store.dispatch("request", {
      state: "video",
      method: "POST",
      url: "getVideo/" + this.$route.params.name,
    });
    this.resizeVideo();
    window.addEventListener("resize", () => {
      this.resizeVideo();
    });
  },
  components: {
    ToolBar,
    CardRecents,
  },
  data: () => ({
    videoWidth: 680,
    viewMoreUrl: "/videos",
  }),
  methods: {
    resizeVideo() {
      let windowWidth = window.innerWidth;
      if (windowWidth < 740) {
        this.videoWidth = windowWidth - 60;
      } else {
        this.videoWidth = 680;
      }
    },
  },
  computed: {
    videoId: function () {
      return getIdFromURL(this.video.link);
    },
    video() {
      return this.$store.state.video;
    },
    releaseVideos() {
      return this.$store.state.releaseVideos;
    },
    base_url() {
      return this.$store.state.base_url;
    },
  },
  watch: {
    "$route.params.name": function () {
      this.$store.dispatch("request", {
        state: "video",
        method: "POST",
        url: "getVideo/" + this.$route.params.name,
      });
    },
  },
};
</script>

<style scoped>
.release-title {
  overflow-y: hidden;
  max-height: 80px;
}
.body-news {
  padding-top: 25px;
  padding-bottom: 35px;
}
</style>