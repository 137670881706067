<template>
  <div>
    <ToolBar />
    <v-container>
      <h3 class="pt-5" v-html="title"></h3>
      <v-row>
        <v-col cols="3" v-for="(item, i) in items" :key="i">
          <CardVideo :baseTo="'/video/'" :item="item" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CardVideo from "@/components/press/CardVideo";
import ToolBar from "@/components/ToolBar";
import { getIdFromURL } from "vue-youtube-embed";

export default {
  components: {
    CardVideo,
    ToolBar,
  },
  data: () => ({
    title: "Nasci no Hospital Dois Pinheiros",
    items: [
      {
        title: "Nasci no H2P 1",
        link: "https://www.youtube.com/watch?v=PErVuyknICM",
        url_pag: "/video1",
      },
      {
        title: "Nasci no H2P 1",
        link: "https://www.youtube.com/watch?v=PErVuyknICM",
        url_pag: "/video1",
      },
      {
        title: "Nasci no H2P 1",
        link: "https://www.youtube.com/watch?v=PErVuyknICM",
        url_pag: "/video1",
      },
      {
        title: "Nasci no H2P 1",
        link: "https://www.youtube.com/watch?v=PErVuyknICM",
        url_pag: "/video1",
      },
      {
        title: "Nasci no H2P 1",
        link: "https://www.youtube.com/watch?v=PErVuyknICM",
        url_pag: "/video1",
      },
    ],
    recents: [
      {
        title: "Mittow Novamente",
        image: `https://img.youtube.com/vi/${getIdFromURL(
          "https://www.youtube.com/watch?v=PErVuyknICM"
        )}/0.jpg`,
        url_pag: "/video1",
      },
    ],
  }),
  methods: {},
};
</script>

<style>
</style>