<template lang="pug">
div
  v-btn(
    v-if="canShare",
    @click="share",
    color="primary",
    fab,
    fixed,
    right,
    bottom,
    small
  )
    v-icon(small) fas fa-share-alt
  .d-flex(v-else)
    v-btn.text-none.mr-2(
      v-for="({ onclick, color, icon, text }, i) in shareButtons",
      @click="onclick",
      :key="i",
      :color="color",
      :fab="xs",
      :x-small="xs",
      :small="!xs",
      depressed,
      dark
    )
      v-icon(:left="!xs", :x-small="xs", :small="!xs") {{ icon }}
      span(v-if="!xs") {{ text }}
</template>

<script>
export default {
  data() {
    return {
      canShare: false,
      shareButtons: [
        {
          onclick: this.shareFacebook,
          color: "#2d88ff",
          icon: "fa fa-facebook",
          text: "Facebook",
        },
        {
          onclick: this.shareTwitter,
          color: "#1da1f2",
          icon: "fa fa-twitter",
          text: "Twitter",
        },
        {
          onclick: this.shareWhatsapp,
          color: "#00e676",
          icon: "fa fa-whatsapp",
          text: "Whatsapp",
        },
      ],
    };
  },
  computed: {
    sm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    share() {
      navigator.share({ url: location.href });
    },
    shareFacebook() {
      window.open(
        "https://www.facebook.com/sharer.php?u=" + location.href,
        "Facebook - Compartilhar",
        "width=600,height=300,left=" +
          (screen.availWidth / 2 - 300) +
          ",top=" +
          (screen.availHeight / 2 - 150) +
          ""
      );
    },
    shareTwitter() {
      window.open(
        "https://twitter.com/share?url=" + location.href,
        "Twitter - Compartilhar",
        "width=600,height=300,left=" +
          (screen.availWidth / 2 - 300) +
          ",top=" +
          (screen.availHeight / 2 - 150) +
          ""
      );
    },
    shareWhatsapp() {
      window.open(
        "https://api.whatsapp.com/send?text=" + encodeURI(location.href)
      );
    },
  },
  created() {
    if (navigator.share && this.xs) this.canShare = true;
  },
};
</script>

<style>
</style>
