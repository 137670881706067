<template>
  <v-container>
    <v-card v-if="profile" elevation="0" width="100%" class="d-flex justify-center pb-10">
      <v-container class="container-profile col-12">
        <v-row justify="center">
          <v-col cols="12" sm="9" md="4">
            <v-card height="100%" max-height="350px">
              <v-img height="100%" :src="this.$store.state.base_url+profile.image">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
            <v-row class="row-btns" justify="center">
              <v-btn
                large
                target="blank"
                v-if="profile.facebook"
                :href="profile.facebook"
                class="my-4 btn-midia"
                icon
              >
                <v-icon color="#217b43">mdi-facebook</v-icon>
              </v-btn>
              <v-btn
                large
                target="blank"
                v-if="profile.instagram"
                :href="profile.instagram"
                class="my-4 btn-midia"
                icon
              >
                <v-icon color="#217b43">mdi-instagram</v-icon>
              </v-btn>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="8">
            <v-card flat class="text-profile">
              <h3 class="title-profile">{{profile.name}}</h3>
              <h4 class="my-5">{{profile.office}}</h4>
              <div class="text-justify" v-html="profile.text"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  beforeCreate() {
    this.$store.dispatch("request", {
      state: "doctor",
      method: "POST",
      url: "getClinicBody/" + this.$route.params.name
    });

  },
  computed: {
    profile() {
      return this.$store.state.doctor
    }
  }
}
</script>

<style scoped>
.title-profile {
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 10px;
}

.subtitle-profile {
  font-size: 1.7rem;
}

.container-profile {
  padding: 10px;
}

.text-profile {
  padding: 15px;
  padding-top: 0px;
}
.text-profile {
  padding: 0px;
}

@media (min-width: 990px) {
  .text-profile {
    padding: 15px;
    padding-top: 0px;
  }

  .text-profile {
    max-height: 350px;
    overflow-y: auto;
  }

  .text-profile::-webkit-scrollbar {
    width: 3px;
    background: #f4f4f4 !important;
  }

  .text-profile::-webkit-scrollbar-track {
    background-color: #f4f4f4 !important;
  }

  .text-profile::-webkit-scrollbar-thumb {
    background: #217b43 !important;
  }
  .col-profile-text {
    padding: 10px 0;
  }
}
</style>>